import React from "react"
import ProductsLayout from "../../components/productsLayout"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import ImageGallery from "react-image-gallery"
import ToploValjaniLimoviTable from "../../components/tables/ToplovaljaniLimoviTable"
import Img1 from "../../images/tvlim.jpg"
import Img2 from "../../images/tvtabla.jpg"
import Img3 from "../../images/tvflah.jpg"
import Head from "../../components/head"

const toplovaljaniLimovi = () => {
  const images = [
    {
      original: `${Img1}`,
      thumbnail: `${Img1}`,
    },
    {
      original: `${Img2}`,
      thumbnail: `${Img2}`,
    },
    {
      original: `${Img3}`,
      thumbnail: `${Img3}`,
    },
  ]
  return (
    <Layout>
      <Head title="Toplovaljani Limovi" />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">Toplovaljani Limovi</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li>
                        <Link to="/proizvodi">Proizvodi</Link>
                      </li>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          Toplovaljani Limovi
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div className="columns">
            <div className="column">
              <ImageGallery
                additionalClass="box"
                infinite={false}
                showNav={false}
                thumbnailPosition="bottom"
                lazyLoad={true}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                originalClass="main-img"
              />
            </div>
            <div className="column">
              <p>
                <strong>TOPLOVALJANI PROIZVODI</strong> od nelegiranih
                konstukcionih čelika definisani standardom SRPS EN 10025,
                koriste se u svim oblastima industrije i građevinarstva.
              </p>
              <br />
              <p>
                <strong>Asortiman:</strong>
                <br />
                Toplovaljani limovi isporučuju se u obliku:
              </p>
              <div className="columns is-multiline">
                <div className="column is-full">
                  <ul>
                    <strong>Table:</strong>
                    <li>debljine: 1,5 - 15 mm</li>
                    <li>širine: 1000 - 1500 mm</li>
                    <li>dužine: do 6000 mm</li>
                  </ul>
                </div>
                <div className="column is-full">
                  <ul>
                    <strong>Debeli limovi:</strong>
                    <li>debljine: 12 - 50 mm</li>
                    <li>širine: 800 - 2000 mm</li>
                    <li>dužine: 2000 - 6000 mm</li>
                  </ul>
                </div>
                <div className="column is-full">
                  <ul>
                    <strong>Flahovi:</strong>
                    <li>debljine: 2 - 10 mm</li>
                    <li>širine: 20 - 100 mm</li>
                    <li>dužine: do 3000 mm</li>
                    <li>materijal: DD11, S275JR, S235JR</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <section className="tab-section">
            <div class="tabs">
              <ul>
                <li className="is-active is-size-3">
                  <a>Hemijski sastav</a>
                </li>
              </ul>
            </div>
            <ToploValjaniLimoviTable />
          </section>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default toplovaljaniLimovi
